import { useState, useEffect, useContext, createContext } from "react";
import { auth, db, firebase } from "../config/firebase";

const authContext = createContext({ user: {} });
const { Provider } = authContext;


export function AuthProvider({ children }) {
    const auth = useAuthProvider();
    return <Provider value={auth}>{children}</Provider>;
}

export const useAuth = () => {
    return useContext(authContext);
};

const useAuthProvider = () => {
    //User should be used to check if user is logged in or not, and for id (use .uid)
    //UserDetails should be used to get details, but can also use to get id (use .id) though it is recommended to use user.uid for id.
    const [user, setUser] = useState(null);
    const [userDetails, setUserDetails] = useState(null);

    const initGoogleSignIn = async () => {
        let response = await auth
            .signInWithRedirect(
                new firebase.auth.GoogleAuthProvider().setCustomParameters({
                    prompt: "select_account",
                })
            )
            .catch((error) => {
                return { error };
            });
    };

    const handleAuthStateChanged = (user) => {
        if (user) {
            setUser(user);
        } else {
            setUser(false);
        }
    };

    useEffect(() => {
        const unsub = auth.onAuthStateChanged(handleAuthStateChanged);
        return () => unsub();
        }, []);
    

    useEffect(() => {
        if (user?.uid) {
            // Subscribe to user document on mount
            const unsubscribe = db
                .collection("users")
                .doc(user.uid)
                .onSnapshot(async (doc) => {
                    if (!doc.exists) {
                        db.collection("users").doc(user.uid).set({
                            _firstLogin: firebase.firestore.FieldValue.serverTimestamp(),
                            name: user.displayName,
                            email: user.email,
                            photoURL: user.photoURL,
                            isQuizDone: false,
                        })
                    } else {
                        setUserDetails({ ...doc.data(), id: doc.id })
                    }
                });
            return () => unsubscribe();
        }
    }, [user]);

    const signOut = () => {
        return auth.signOut().then(() => setUser(false));
    };

    return {
        user,
        userDetails,
        signOut,
        initGoogleSignIn,
    };
};

