import React, { useState } from 'react';
import './AppNavbar.css';
import { Link, useLocation  } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { Button } from '@material-ui/core';
import YNWBLogo from '../../images/YNWBLogo.png';

export default function AppNavbar() {
    const [click, setClick] = useState(false);

    const { signOut } = useAuth();
    const currentLocation = useLocation().pathname;

    function handleClick() {
        setClick(prevClick => !prevClick)
    }

    function closeMobileMenu() {
        setClick(false);
    }

    function closeAndSignOut() {
        signOut();
        setClick(false);
    }

    return (
        <nav className="appNavbar">
            <div className="appNavbar-container">
                {/* Replace this with user photo and Name*/}
                <div className="appNavbar-logo">
                    <Link to="/" onClick={closeMobileMenu}><img src={YNWBLogo} alt="You're Not Wrong But logo" className="appNavbar-logo__image"></img></Link>
                </div> 
                <div className="menu-icon" onClick={handleClick}>
                    <i className={click ? 'fas fa-times' : 'fas fa-bars appNav-bars'} />
                </div>
                <ul className={click ? 'appNav-menu active' : 'appNav-menu'}>
                    <li className="appNav-item">
                        <Link to="/app" className={`appNav-links ${currentLocation === "/app" ? "appNav-links-active" : "" }`} onClick={closeMobileMenu}>
                            Dashboard
                        </Link>
                    </li>
                    <li className="appNav-item">
                        <Link to="/app/score" className={`appNav-links ${currentLocation === "/app/score" ? "appNav-links-active" : "" }`} onClick={closeMobileMenu}>
                            Score
                        </Link>
                    </li>
                    <li className="appNav-item">
                        <Link to="/app/quiz" className={`appNav-links ${currentLocation === "/app/quiz" ? "appNav-links-active" : "" }`} onClick={closeMobileMenu}>
                            Quiz
                        </Link>
                    </li>
                    <li className="appNav-item">
                        <Link to="/app/contact" className={`appNav-links ${currentLocation === "/app/contact" ? "appNav-links-active" : "" }`} onClick={closeMobileMenu}>
                            Contact Us
                        </Link>
                    </li>
                    {/* This appNav-item-desktop will only appear when above 960px */}
                    <li className="appNav-item-desktop">
                        <Link to="/" className="appNav-links appNav-links-desktop appNav-item-button" onClick={signOut}>
                            <Button variant="outlined">Sign Out</Button>
                        </Link>
                    </li>
                    {/* this appNav-item-mobile will only appear when below 960px */}
                    <li className="appNav-item-mobile">
                        <Link to="/" className="appNav-links-mobile" onClick={closeAndSignOut}>Sign Out</Link>
                    </li>
                </ul>
            </div>
        </nav>
    )
}
