import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuth } from '../../contexts/AuthContext';
import Button from '@material-ui/core/Button';
import emailjs from 'emailjs-com';

//Yup Schema
const contactUsSchema = yup.object().shape({
    name: yup.string().required('Your name is required'),
    email: yup.string().email().required('A valid Email is required'),
    topic: yup.string().required('A topic is required'),
    text: yup.string().required('Your Message is required'),
})

function ContactUs({ mainClassName }) {

    const { user, userDetails } = useAuth();

    const [emailSent, setEmailSent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        email: "", 
        topic: "",  
        text: "",
    });

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(contactUsSchema)
    });

    useEffect(() => {
        setFormData({
            name: userDetails && user ? userDetails.name : "",
            email: userDetails && user ? userDetails.email : "",
            topic: "",
            text: "",
        })
    }, [user, userDetails])

    const handleFormInput = (e) => {
        const { value, name } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    //Function that handles sending the emails to me
    const sendMessage = (data) => {

        //Sends the email with emailjs
        const newData = {...data, isUser: userDetails ? "Yes" : "No"};

        setLoading(true)
        emailjs.send('service_8rnbqom', 'template_cukcnii', newData, 'user_xOUvYC6mWaLGGDuRJAgGf')
            .then((result) => {
                setEmailSent(true)
                //Empty all the fields again
                setFormData({
                    name: userDetails && user ? userDetails.name : "",
                    email: userDetails && user ? userDetails.email : "",
                    topic: "",
                    text: "",
                })
                setLoading(false)
            }, (error) => {
                setLoading(false)
            });
    }

    return (
        <form className={`${mainClassName}__form`} onSubmit={handleSubmit(sendMessage)}>
            <h1>Contact Us!</h1>
            <p>Feel free to reach out for any questions, feedback, or to report any bugs</p>
            <div className={`${mainClassName}__form-row`}>
                <input
                    {...register('name')}
                    type="text"
                    placeholder="Name (Mandatory)"
                    name="name"
                    value={formData?.name}
                    onChange={handleFormInput}
                />
                <p className={`${mainClassName}__form-error`}>{errors.name?.message}</p>
            </div>
            <div className={`${mainClassName}__form-row`}>
                <input
                    {...register('email')}
                    type="text"
                    placeholder="Email (Mandatory)"
                    name="email"
                    value={formData?.email}
                    onChange={handleFormInput}
                />
                <p className={`${mainClassName}__form-error`}>{errors.email?.message}</p>
            </div>
            <div className={`${mainClassName}__form-row`}>
                <input
                    {...register('topic')}
                    type="topic"
                    placeholder="Topic/Subject of Inquiry"
                    name="topic"
                    value={formData?.topic}
                    onChange={handleFormInput}
                />
                <p className={`${mainClassName}__form-error`}>{errors.topic?.message}</p>
            </div>
            <div className={`${mainClassName}__form-row`}>
                <textarea
                    {...register('text')}
                    cols="2"
                    rows="3"
                    placeholder="Your Message"
                    name="text"
                    value={formData?.text}
                    onChange={handleFormInput}
                />
                <p className={`${mainClassName}__form-error`}>{errors.text?.message}</p>
            </div>
            {emailSent? <p>Email sent successfully! We'll get back to you soon!</p>: null}
            <Button
                className={`${mainClassName}__form-button`}
                variant="contained"
                type="submit"
                disabled={loading}
            >
                {loading? "LOADING..." : "SUBMIT!"}
            </Button>
        </form>
    )
}

export default ContactUs
