import React, { useEffect, useState } from 'react';
import { db } from '../../config/firebase';
import { useAuth } from '../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import './DashboardPage.css';
export default function DashboardPage() {

    const { userDetails } = useAuth();

    const [posts, setPosts] = useState();

    //Getting the post data
    useEffect(() => {
        if (userDetails) {

            //get the user data to get the score

            let politicalIdentity = findPoliticalIdentity(userDetails.econScore, userDetails.socialScore);
            //Then get the post data for the dashboard
            const unsub = db.doc(`groups/${politicalIdentity}`).onSnapshot(snap => {
                const snapData = snap.data();
                setPosts(snapData?.posts?.map(post => post))
            })

            return () => {
                unsub();
            }

        }

    }, [userDetails])

    return (
        <div className="dashboard">
            <div className="dashboard__header">
                <h1>Your Feed</h1>
                <p>This is updated weekly on Sunday 00:00 Hrs New York Time (GMT-4)</p>
            </div>
            <div className="dashboard__posts">
                {userDetails?.isQuizDone ?
                    posts?.map((post, index) => {
                        return (
                            <div className="dashboard__post" key={index}>
                                {post.image !== null ?
                                    <div className="dashboard__postImage" style={{ backgroundImage: `url(${post.image})` }}></div> : null}
                                <div className="dashboard__postContent">
                                    <h1>{post.title}</h1>
                                    <h2><span>Source:</span> {post.source}</h2>
                                    <h3><span>Author:</span> {post.author}</h3>
                                    <p>{post.description.split(" ").length > 16 ? post.description.split(" ").slice(0, 15).join(' ') + "..." : post.description}</p>
                                    <a href={post.url} target="_blank" rel="noopener noreferrer"><p>View full article</p></a>
                                </div>
                            </div>
                        )
                    }) : <div className="dashboard__quizNotDone">
                        <p>It looks like you do't have a feed as you haven't taken the Political Axis Quiz yet!</p>
                        <Link to="/app/quiz"><Button variant="outlined">Take the Quiz!</Button></Link>
                    </div>
                }
            </div>
        </div>
    )
}

const findPoliticalIdentity = (econScore, socialScore) => {
    //Middle
    if ((econScore <= 0.3 && econScore >= -0.3) && (socialScore <= 0.3 && socialScore >= -0.3)) return 'moderate'
    //Leftists
    if (econScore < 0) {
        if (econScore < -0.45) {
            //Top Left
            if (socialScore > 0) {
                return 'topLeft'
                //Bottom left
            } else {
                return 'bottomLeft'
            }
        } else {
            //Middle top left
            if (socialScore > 0) {
                return 'midTopLeft'
                //Middle bottom left
            } else {
                return 'midBottomLeft'
            }
        }
        //Rightwingers
    } else if (econScore >= 0) {
        if (econScore > 0.45) {
            //Top right
            if (socialScore > 0) {
                return 'topRight'
                //Bottom right
            } else {
                return 'bottomRight'
            }
        } else {
            //Middle top right
            if (socialScore > 0) {
                return 'midTopRight'
                //Middle bottom right
            } else {
                return 'midBottomRight'
            }
        }
    }
}
