import React, { useState } from 'react';
import './LoginNavbar.css';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import YNWBLogo from '../../images/YNWBLogo.png';

export default function Navbar() {
    const [click, setClick] = useState(false);

    const { user, signOut } = useAuth();

    function handleClick() {
        setClick(prevClick => !prevClick)
    }

    function closeMobileMenu() {
        setClick(false);
    }

    function closeAndSignOut() {
        signOut();
        setClick(false);
    }

    return (
        <nav className="loginNavbar">
            <div className="loginNavbar-container">
                <div className="loginNavbar-logo">
                    <Link to="/" onClick={closeMobileMenu}><img src={YNWBLogo} alt="You're Not Wrong But logo" className="loginNavbar-logo__image"></img></Link>
                </div> {/*Link replaces the A tag once we ihave multiple pages and install React Router Dom*/}
                <div className="menu-icon" onClick={handleClick}>
                    <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                </div>
                <ul className={click ? 'loginNav-menu active' : 'loginNav-menu'}>
                    <li className="loginNav-item">
                        <a href="/" className="loginNav-links" onClick={closeMobileMenu}>
                            RETURN <i class="fas fa-home"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    )
}
