export const configQuestions = [
    {
        id: 1,
        statement: "I believe in progressive taxation",
        axis: "econ",
        options: [
            {
                value: 3,
                label: "Strongly disagree",
            },
            {
                value: 1,
                label: "Disagree",
            },
            {
                value: 0,
                label: "Neutral",
            },
            {
                value: -1,
                label: "Agree",
            },
            {
                value: -3,
                label: "Strongly Agree",
            },
        ]
    },
    {
        id: 2,
        statement: "I wish I paid more taxes",
        axis: "econ",
        options: [
            {
                value: 2,
                label: "Strongly disagree",
            },
            {
                value: 1,
                label: "Disagree",
            },
            {
                value: 0,
                label: "Neutral",
            },
            {
                value: -1,
                label: "Agree",
            },
            {
                value: -2,
                label: "Strongly Agree",
            },
        ]
    },
    {
        id: 3,
        statement: "Marijuana should be legal for all adults",
        axis: "social",
        options: [
            {
                value: 2,
                label: "Strongly disagree",
            },
            {
                value: 1,
                label: "Disagree",
            },
            {
                value: 0,
                label: "Neutral",
            },
            {
                value: -1,
                label: "Agree",
            },
            {
                value: -2,
                label: "Strongly Agree",
            },
        ]
    },
    {
        id: 4,
        statement: "No authority should be left unchecked or unquestioned",
        axis: "social",
        options: [
            {
                value: 2,
                label: "Strongly disagree",
            },
            {
                value: 1,
                label: "Disagree",
            },
            {
                value: 0,
                label: "Neutral",
            },
            {
                value: -1,
                label: "Agree",
            },
            {
                value: -2,
                label: "Strongly Agree",
            },
        ]
    },
    {
        id: 5,
        statement: "Some businesses are too big to fail",
        axis: "econ",
        options: [
            {
                value: 1,
                label: "Strongly disagree",
            },
            {
                value: 1,
                label: "Disagree",
            },
            {
                value: 0,
                label: "Neutral",
            },
            {
                value: -1,
                label: "Agree",
            },
            {
                value: -1,
                label: "Strongly Agree",
            },
        ]
    },
    {
        id: 6,
        statement: "Karl Marx: 'From each according to his ability, to each according to his needs'",
        axis: "econ",
        options: [
            {
                value: 3,
                label: "Strongly disagree",
            },
            {
                value: 1,
                label: "Disagree",
            },
            {
                value: 0,
                label: "Neutral",
            },
            {
                value: -1,
                label: "Agree",
            },
            {
                value: -3,
                label: "Strongly Agree",
            },
        ]
    },
    {
        id: 7,
        statement: "If there is nothing to hide, then the government accessing your communications should not be a big deal",
        axis: "social",
        options: [
            {
                value: -2,
                label: "Strongly disagree",
            },
            {
                value: -1,
                label: "Disagree",
            },
            {
                value: 0,
                label: "Neutral",
            },
            {
                value: 1,
                label: "Agree",
            },
            {
                value: 2,
                label: "Strongly Agree",
            },
        ]
    },
    {
        id: 8,
        statement: "If a country accepts migrants, it is important that migrants assimilate to that nation’s culture and learn to speak the native language.",
        axis: "social",
        options: [
            {
                value: -1,
                label: "Strongly disagree",
            },
            {
                value: -1,
                label: "Disagree",
            },
            {
                value: 0,
                label: "Neutral",
            },
            {
                value: 1,
                label: "Agree",
            },
            {
                value: 1,
                label: "Strongly Agree",
            },
        ]
    },
    {
        id: 9,
        statement: "I believe in excise taxes on cigarettes, alcohol and other harmful drugs",
        axis: "econ",
        options: [
            {
                value: 2,
                label: "Strongly disagree",
            },
            {
                value: 1,
                label: "Disagree",
            },
            {
                value: 0,
                label: "Neutral",
            },
            {
                value: -1,
                label: "Agree",
            },
            {
                value: -2,
                label: "Strongly Agree",
            },
        ]
    },
    {
        id: 10,
        statement: "No billionaires are possibly deserving of their wealth",
        axis: "econ",
        options: [
            {
                value: 2,
                label: "Strongly disagree",
            },
            {
                value: 1,
                label: "Disagree",
            },
            {
                value: 0,
                label: "Neutral",
            },
            {
                value: -1,
                label: "Agree",
            },
            {
                value: -2,
                label: "Strongly Agree",
            },
        ]
    },
    {
        id: 11,
        statement: "Affirmative action is necessary and beneficial for society",
        axis: "social",
        options: [
            {
                value: -2,
                label: "Strongly disagree",
            },
            {
                value: -1,
                label: "Disagree",
            },
            {
                value: 0,
                label: "Neutral",
            },
            {
                value: 1,
                label: "Agree",
            },
            {
                value: 2,
                label: "Strongly Agree",
            },
        ]
    },
    {
        id: 12,
        statement: "The United Nations should have a military to enforce its resolutions",
        axis: "social",
        options: [
            {
                value: -2,
                label: "Strongly disagree",
            },
            {
                value: -1,
                label: "Disagree",
            },
            {
                value: 0,
                label: "Neutral",
            },
            {
                value: 1,
                label: "Agree",
            },
            {
                value: 2,
                label: "Strongly Agree",
            },
        ]
    },
    {
        id: 13,
        statement: "In the future if automation replaces most jobs, a universal basic income will be necessary",
        axis: "econ",
        options: [
            {
                value: 2,
                label: "Strongly disagree",
            },
            {
                value: 1,
                label: "Disagree",
            },
            {
                value: 0,
                label: "Neutral",
            },
            {
                value: -1,
                label: "Agree",
            },
            {
                value: -2,
                label: "Strongly Agree",
            },
        ]
    },
    {
        id: 14,
        statement: "The government should price control the healthcare industry",
        axis: "econ",
        options: [
            {
                value: 2,
                label: "Strongly disagree",
            },
            {
                value: 1,
                label: "Disagree",
            },
            {
                value: 0,
                label: "Neutral",
            },
            {
                value: -1,
                label: "Agree",
            },
            {
                value: -2,
                label: "Strongly Agree",
            },
        ]
    },
    {
        id: 15,
        statement: "Monarchies should not have much major political power anymore",
        axis: "social",
        options: [
            {
                value: 2,
                label: "Strongly disagree",
            },
            {
                value: 1,
                label: "Disagree",
            },
            {
                value: 0,
                label: "Neutral",
            },
            {
                value: -1,
                label: "Agree",
            },
            {
                value: -2,
                label: "Strongly Agree",
            },
        ]
    },
    {
        id: 16,
        statement: "All handguns should be illegal",
        axis: "social",
        options: [
            {
                value: -2,
                label: "Strongly disagree",
            },
            {
                value: -1,
                label: "Disagree",
            },
            {
                value: 0,
                label: "Neutral",
            },
            {
                value: 1,
                label: "Agree",
            },
            {
                value: 2,
                label: "Strongly Agree",
            },
        ]
    }
]