import React, { useEffect, useState } from 'react';
import { firebase, db } from '../../config/firebase';
import { useAuth } from '../../contexts/AuthContext';
import Slider from '@material-ui/core/Slider';
import PoliticalAxisChart from '../../components/Charts/PoliticalAxisChart';
import Loader from '../../components/Loader/Loader';
import { Link } from 'react-router-dom';
import './ScorePage.css';
import { useMediaQuery } from 'react-responsive'
import { Button } from '@material-ui/core';

function ScorePage() {

    const [color, setColor] = useState('#ff6384');
    const [econScore, setEconScore] = useState(0);
    const [socialScore, setSocialScore] = useState(0);
    const [loading, setLoading] = useState(true);
    const [isQuizDone, setIsQuizDone] = useState(false);
    const [saved, setSaved] = useState(false); //to show users that their changes were saved
    const [chartHeightAndWidth, setChartHeightAndWidth] = useState(430);
    let componentMounted = true; // component is mounted (this is to fix this error --> Can't perform a React state update on an unmounted component. This is a 
    //no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.)

    const { user, userDetails } = useAuth();

    useEffect(() => {
        if (!userDetails?.id) return
        if (componentMounted) {
            setIsQuizDone(userDetails.isQuizDone)
            setLoading(false)
            setEconScore(userDetails.econScore)
            setSocialScore(userDetails.socialScore)
        }
        return () => { // This code runs when component is unmounted
            componentMounted = false; // set it to false if we leave the page
        }

    }, [userDetails])

    useEffect(() => {
        if (econScore < 0 && socialScore > 0) {
            setColor('#ebb113')
        }
        else if (econScore > 0 && socialScore < 0) {
            setColor('#eb5f13')
        }
        else if (econScore < 0 && socialScore < 0) {
            setColor('#c21979')
        } else {
            setColor('#ff6384')
        }
    }, [econScore, socialScore])

    //Request for scores up here, then pass it into the chart with the corresponding color.

    const handleEconScoreChange = (e, newValue) => {
        setEconScore(newValue)
    }

    const handleSocialScoreChange = (e, newValue) => {
        setSocialScore(newValue)
    }

    const handleSubmitScoreChange = (e) => {
        e.preventDefault();
        db.doc(`users/${user?.uid}`).update({
            econScore: econScore,
            socialScore: socialScore,
            changeScoreCounter: firebase.firestore.FieldValue.increment(1)
        }).then(
            setSaved(true)
        )
    }

    const isBigLaptop = useMediaQuery({ query: '(max-width: 1500px'})
    const isLaptop = useMediaQuery({ query: '(max-width: 1200px)' })
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const isSmallMobile = useMediaQuery({ query: '(max-width: 480px)' })
    const isVerySmallMobile = useMediaQuery({ query: '(max-width: 380px)' })
    const isVeryVerySmallMobile = useMediaQuery({ query: '(max-width: 330px)' })

    useEffect(() => {
        if (isVeryVerySmallMobile) {
            setChartHeightAndWidth(235)
        } else if (isVerySmallMobile) {
            setChartHeightAndWidth(270)
        } else if (isSmallMobile) {
            setChartHeightAndWidth(300)
        } else if (isMobile) {
            setChartHeightAndWidth(350)
        } else if (isLaptop) {
            setChartHeightAndWidth(400)
        } else if (isBigLaptop){
            setChartHeightAndWidth(412)
        } else {
            setChartHeightAndWidth(430)
        }

    }, [isBigLaptop, isLaptop, isMobile, isSmallMobile, isVerySmallMobile, isVeryVerySmallMobile])

    return (
        <div className="scorePage">
            {loading ? <div className="scorePage__loader"><Loader /></div> : (!isQuizDone ? <div className="scorePage__quizNotDone">
                <p>It looks like you do't have a Score as you haven't taken the Political Axis Quiz yet!</p>
                <Link to="/app/quiz"><Button variant="outlined">Take the Quiz!</Button></Link>
            </div> : <>
                <div className="scorePage__header">
                    <h1>Your Political Spectrum Score</h1>
                </div>
                <div className="scorePage__scoreShowCase">
                    {/* Render the chart here */}
                    <form onSubmit={handleSubmitScoreChange}>
                        <div className="scorePage__chartContainer">
                            <PoliticalAxisChart econScore={econScore || 0} socialScore={socialScore || 0} color={color} height={`${chartHeightAndWidth}px`} width={`${chartHeightAndWidth}px`} />
                            <Slider
                                value={socialScore || 0}
                                onChange={handleSocialScoreChange}
                                aria-labelledby="vertical-slider"
                                orientation="vertical"
                                min={-1}
                                max={1}
                                step={0.01}
                                id="socialScoreSlider"
                                style={{ height: `${chartHeightAndWidth}px` }}
                            />
                        </div>
                        <Slider
                            value={econScore || 0}
                            onChange={handleEconScoreChange}
                            aria-labelledby="continuous-slider"
                            min={-1}
                            max={1}
                            step={0.01}
                            id="econScoreSlider"
                            style={{ width: `${chartHeightAndWidth}px` }}
                        />
                        {saved ? <p>"Your changes were saved!"</p> : null}
                        <Button type="submit" variant="contained">Save Changes</Button>
                    </form>
                    <div className="scorePage__alternativeContent">
                        <div className="scorePage__alternativeContentQuiz">
                            <p>Don't feel that we accurately evaluated you? That's okay, you can always change it or take the quiz again!</p>
                            <Link to="/app/quiz"><Button variant="contained">QUIZ</Button></Link>
                        </div>
                        <Link to="/app"><Button variant="contained">HEAD TO DASHBOARD</Button></Link>
                    </div>
                </div>
            </>)}
        </div>
    )
}

export default ScorePage
