import React, { useState } from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { Button } from '@material-ui/core';
import YNWBLogo from '../../images/YNWBLogo.png';

export default function Navbar() {
    const [click, setClick] = useState(false);

    const { user, signOut } = useAuth();

    function handleClick() {
        setClick(prevClick => !prevClick)
    }

    function closeMobileMenu() {
        setClick(false);
    }

    function closeAndSignOut() {
        signOut();
        setClick(false);
    }

    return (
        <nav className="navbar">
            <div className="navbar-container">
                <div className="navbar-logo">
                    <Link to="/" onClick={closeMobileMenu}><img src={YNWBLogo} alt="youre not wrong but logo" className="navbar-logo__image"></img></Link>
                </div> {/*Link replaces the A tag once we ihave multiple pages and install React Router Dom*/}
                <div className="menu-icon" onClick={handleClick}>
                    <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className="nav-item">
                        <a href="#about" className="nav-links" onClick={closeMobileMenu}>
                            About
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="#test" className="nav-links" onClick={closeMobileMenu}>
                            Test
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="#contact" className="nav-links" onClick={closeMobileMenu}>
                            Contact Us
                        </a>
                    </li>
                    {user ? <li className="nav-item">
                        <Link to="/app" className="nav-links nav-links-dashboard nav-item-button" onClick={closeMobileMenu}>
                            <Button variant="outlined" id="dasboardButton">DASHBOARD</Button>
                        </Link>
                    </li> : <Link to="/login" className="nav-links nav-links-desktop nav-item-button" onClick={closeMobileMenu}>
                        <Button variant="outlined">SIGN IN</Button>
                    </Link>}
                    {/* This nav-item-desktop will only appear when above 960px */}
                    <li className="nav-item-desktop">
                        {!user ? <><Link to="/login" className="nav-links nav-links-desktop nav-item-button" onClick={closeMobileMenu}>
                            <Button variant="outlined">GET STARTED</Button>
                        </Link></> : <Link to="/" className="nav-links nav-links-desktop nav-item-button" onClick={signOut}>
                            <Button variant="outlined">SIGN OUT</Button>
                        </Link>
                        }
                    </li>
                    {/* this nav-item-mobile will only appear when below 960px */}
                    <li className="nav-item-mobile">
                        {!user ? <Link to="/login" className="nav-links-mobile" onClick={closeMobileMenu}>
                            GET STARTED
                        </Link> : <Link to="/" className="nav-links-mobile" onClick={closeAndSignOut}>
                            SIGN OUT
                        </Link>
                        }
                    </li>
                </ul>
            </div>
        </nav>
    )
}
