import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"

const firebaseConfig = {
  apiKey: "AIzaSyBnoNIy90_noN9E7TDR4jUqY4chpvFRtLY",
  authDomain: "poliview-96ab2.firebaseapp.com",
  projectId: "poliview-96ab2",
  storageBucket: "poliview-96ab2.appspot.com",
  messagingSenderId: "1071998650157",
  appId: "1:1071998650157:web:04f9363b27c1d2c6e835a4",
  measurementId: "G-TXKXG44ZQB",
}

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}

firebase.firestore().settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
})

const app = firebase.app()
const auth = firebase.auth()
const db = firebase.firestore()
const functions = firebase.functions()

db.enablePersistence()

export { firebase, auth, db, functions }

console.log(app.name ? "Firebase Mode Activated!" : "Firebase not working :(")
