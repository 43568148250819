import React from "react";
import "./ProgressBar.css";

const ProgressBar = ({ completed }) => {
  
    const fillerStyles = {
      height: '100%',
      width: `${completed}%`,
      backgroundColor: Math.random() < 0.5 ? '#76AFE4' : '#e06a68e1',
      transition: 'width 0.1s ease-in-out',
      borderRadius: 'inherit',
      textAlign: 'right'
    }
  
    return (
      <div className="progressBar">
        <div className="progressBar__filler" style={fillerStyles}>
          <span className="progressBar__label">{`${completed}%`}</span>
        </div>
      </div>
    );
  };
  
  export default ProgressBar;