import React, { useState } from 'react';
import ReactPaginate from "react-paginate";
import { configQuestions } from './QuizConfig';
import { firebase, db } from '../../config/firebase';
import { useAuth } from '../../contexts/AuthContext';
import { Redirect } from 'react-router-dom';
import Loader from '../../components/Loader/Loader'; 
import Button from '@material-ui/core/Button';
import './QuizPage.css';

export default function QuizPage() {

    const { user, userDetails } = useAuth();

    const [questions, setQuestions] = useState(configQuestions);
    const [answers, setAnswers] = useState(new Array(configQuestions.length).fill(0))
    const [pageNumber, setPageNumber] = useState(0);
    const [loading, setLoading] = useState(false);
    const [finishedQuiz, setFinishedQuiz] = useState(false);

    //Important Pagination Variables
    const questionsPerPage = 6; //<- Only thing I need to change
    const questionsVisited = pageNumber * questionsPerPage;
    const pageCount = Math.ceil(configQuestions.length / questionsPerPage);

    //Handle the change in page
    const handlePageChange = ({ selected: newPageNumber }) => {
        setPageNumber(newPageNumber);
        window.scrollTo(0, 0);
    }

    const handleAnswerChange = (e) => {
        const newAnswers = [...answers];
        //We index the answers with the name (which will be 1,2,3, the id of the question)
        //And then we set it to the value of it (which will be -1,-2,-3 etc...)
        newAnswers[e.target.name - 1] = parseInt(e.target.value);
        setAnswers(newAnswers);
    }

    //Handle final form submission
    const submitForm = async (e) => {
        e.preventDefault();

        setLoading(true); //Set loader as loading at start

        let userEconSum = 0;
        let econMax = 2; //Start at 2 as an offset
        let userSocialSum = 0;
        let socialMax = 2; //start at 2 as an offset

        //Defining constants such as the offset, and the allowed the min and maxes
        const econOffset = 2;
        const socialOffset = 0;
        const allowedEconMin = -11;
        const allowedEconMax = 10;
        const allowedSocialMin = -10;
        const allowedSocialMax = 10; //found another mistake

        configQuestions.forEach(question => {
            if (question.axis === "econ") {
                userEconSum += answers[question.id - 1]
                econMax += Math.abs(parseInt(question.options[0].value))
            }
            if (question.axis === "social") {
                userSocialSum += answers[question.id - 1]
                socialMax += Math.abs(parseInt(question.options[0].value))
            }
        })
        if (userEconSum !== 0) {
            userEconSum += econOffset //Now we only add the offset if the final score was not equal to 0
        }

        if (userSocialSum !== 0) {
            userSocialSum += socialOffset //Only add the offset if the final score is not equal to 0
        }
        //Check if score exceeds max or min, and adjust it if it does.
        //For econ
        if (userEconSum < allowedEconMin) {
            userEconSum = allowedEconMin
        } else if (userEconSum > allowedEconMax) {
            userEconSum = allowedEconMax
        }
        //For social
        if (userSocialSum < allowedSocialMin) {
            userSocialSum = allowedSocialMin
        } else if (userSocialSum > allowedSocialMax) {
            userSocialSum = allowedSocialMax //<-- found a mistake
        }

        //Calculate the social and econ score, then submits it.
        const finalEconScore = Math.round((userEconSum / econMax) * 100 + Number.EPSILON) / 100; /*<-- this just rounds it to 2 
        decimal places (change the 100's to 1000s if want 3 decimal places)*/
        const finalSocialScore = Math.round((userSocialSum / socialMax) * 100 + Number.EPSILON) / 100;

        if (userDetails.isQuizDone === false) {
            await db.doc(`users/${user.uid}`).update({
                "isQuizDone": true,
                "econScore": finalEconScore,
                "socialScore": finalSocialScore,
                "firstEconScore": finalEconScore,
                "firstSocialScore": finalSocialScore,
                "changeScoreCounter": 0,
            }).then(val => {
                setFinishedQuiz(true);
                setLoading(false);
            })
        } else {
            //Not first time, so add to changeScore counter and don't change the initial
            await db.doc(`users/${user.uid}`).update({
                "isQuizDone": true,
                "econScore": finalEconScore,
                "socialScore": finalSocialScore,
                "changeScoreCounter": firebase.firestore.FieldValue.increment(1),
            }).then(val => {
                setFinishedQuiz(true);
                setLoading(false);
            })
        }

        //Then we redirect to a page called see score visually on a chart. There, the user can see his score. If he/she feels that their 
        //score is not an accurate representation, they can enter new finalEcon and finalSocial scores.

    }

    //Get the questions to display
    const displayQuestions = questions
        .slice(questionsVisited, questionsVisited + questionsPerPage)
        .map(question => {
            return (
                /* Put the form element here */
                <div className="quizPage__form-question" key={question.id} onChange={handleAnswerChange}>
                    <h2>{question.statement}</h2>
                    {/* Mapping the radio button answer choices */}
                    <div className="quizPage__form-radioButtonContainer">
                        {question.options.map(option => (
                            <div key={`${question.id} ${option.label}`}>
                                <input
                                    type="radio"
                                    name={question.id}
                                    value={option.value}
                                />
                                <span>{option.label}</span>
                            </div>
                        )
                        )}
                    </div>
                </div>
            )
        })

    return (
        <div className="quizPage">
            {finishedQuiz ? <Redirect to="/app/score" /> : null}
            {pageNumber === 0 ? <div className="quizPage__intro">
                <h1>Welcome to the Political Axis Quiz!</h1>
                <p>Answer a series of questions and we'll do our best to determine where you fall on the political axis! If you leave
                    any questions unanswered they will be treated as though you selected "neutral"</p>
            </div> : null}
            <form onSubmit={submitForm} className="quizPage__form">
                {displayQuestions}
                {pageNumber === pageCount - 1 ? (loading ? <div className="quizPage__loader"><Loader /></div> : <Button type="submit" variant="outlined" size="large">Submit</Button>) : null}
            </form>
            <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={pageCount}
                onPageChange={handlePageChange}
                containerClassName={"paginationButtons"}
                previousLinkClassName={"previousButton"}
                nextLinkClassName={"nextButton"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
            />
        </div>
    )
}
