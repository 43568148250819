import React from 'react';
import ContactUs from '../../components/Forms/ContactUs';
import ContactMailLetterImage from '../../images/Contact.png'
import './ContactUsPage.css';

export default function ContactUsPage() {
    return (
        <div className="contactUs">
            <ContactUs mainClassName="contactUs"/>
            <img className="contactUs__icon" src={ContactMailLetterImage} alt="mail icon"></img>
        </div>
    )
}
