import React from 'react';
import { Scatter } from 'react-chartjs-2';
import './PoliticalAxisChart.css';

function PoliticalAxisChart({ econScore, socialScore, color, height, width }) {

    return (
        <div className="politicalAxisChart" style={{position: "relative", height: height, width: width}}>
            <Scatter
                data={{
                    datasets: [
                        {
                            label: "Your Score",
                            data: [
                                { x: econScore, y: socialScore },
                            ],
                            backgroundColor: color,
                            pointRadius: 25,
                            pointHoverRadius: 30,
                            borderColor: 'black',
                            pointStyle: 'rectRot'
                        },
                        {
                            data: [
                                { x: -1, y: -1 },
                                { x: 1, y: 1 },
                                { x: -1, y: 1 },
                                { x: 1, y: -1 },
                            ],
                            pointRadius: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0)',

                        },
                    ],
                }}
                options={{
                    scales: {
                        x: {
                            display: false,
                        },
                        y:
                        {
                            display: false,
                        }
                    },
                    aspectRatio: 1,
                    plugins: {
                        legend: {
                            display: false
                        }
                    },
                    animation: {
                        duration: 0
                    },
                    responsive: true,
                    maintainAspectRatio: true,
                }}
            />
        </div>
    )
}

export default PoliticalAxisChart;
