import React, { useState, useEffect } from 'react';
import './LandingPage.css';
import Navbar from '../../components/Navbar/Navbar';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import ContactUs from '../../components/Forms/ContactUs';
import Button from '@material-ui/core/Button';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom';
import politicalAxisGraph from '../../images/politicalAxis.png'
import "animate.css/animate.min.css";

function LandingPage() {

    const [completed, setCompleted] = useState(0);
    const [loading, setLoading] = useState(true);

    const progressBarBreakpoints = [getRandomInt(12, 48), getRandomInt(67, 92), 100];

    useEffect(() => {
        setTimeout(() => setCompleted(progressBarBreakpoints[0]), 200)
        setTimeout(() => setCompleted(progressBarBreakpoints[1]), 350)
        setTimeout(() => {
            setCompleted(progressBarBreakpoints[2])
            setLoading(false)
        }, 600)

    }, []);

    function getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
    }

    return (
        <div className="landing">
            {loading ? <div className="loading">
                <h1>Welcome!</h1>
                <ProgressBar completed={completed} />
            </div> :
                <>
                    <Navbar />
                    <section className="landing__intro">
                        <div id="intro" className="landing__spacer"></div>
                        <div className="landing__introContainer">
                            <div id="side_one">
                                <h1>You're Not</h1>
                            </div>
                            <div id="side_two">
                                <h1>Wrong But...</h1>
                            </div>
                            <div className="landing__introPrompt">
                                <p>There's two sides to every story.</p>
                                <p>Are you prepared to hear the other side?</p>
                                <Link to="/login"><Button variant="outlined">Get Started</Button></Link>
                            </div>
                        </div>
                    </section>
                    <section className="landing-about">
                        <div id="about" className="landing__spacer"></div>
                        <ScrollAnimation animateOnce duration={0.5} animateIn="animate__fadeInUp">
                            <h1 className="landing-about__heading">ABOUT</h1>
                            <div className="landing-about__container">
                                <div className="landing-about__content">
                                    <h1>Capitalize off Biased News</h1>
                                    <p>
                                        Despite what they might claim, Media Outlets can be biased!
                                    </p>
                                    <br />
                                    <p>
                                        But this doesn't have to be a bad thing! We help you <b>Capitalize</b> off this for you to explore more perspectives!
                                    </p>
                                    <h1>"Why should I hear what the <span>other side</span> has to say?"</h1>
                                    <p>
                                        We strongly believe the vast majority of humans want to make the world a better place. However, our disagreements stem from our different ideas
                                        on how to achieve that goal. By understanding the ideas of the other side, we can <b>end tribalism</b> in politics and
                                        achieve better change.
                                    </p>
                                </div>
                                <div className="landing-about__image">
                                    <img src="https://www.allsides.com/sites/default/files/AllSidesMediaBiasChart-Version1.1_0.jpg" alt="major news outlets"></img>
                                </div>
                            </div>
                        </ScrollAnimation>
                    </section>
                    <section className="landing-test">
                        <div id="test" className="landing__spacer"></div>
                        <ScrollAnimation animateOnce duration={0.5} animateIn="animate__fadeInUp">
                            <h1 className="landing-test__heading">TEST</h1>
                            <div className="landing-test__container">
                                <div className="landing-test__image">
                                    <img src={politicalAxisGraph} alt="major news outlets"></img>
                                </div>
                                <div className="landing-test__content">
                                    <h1>Take The Test!</h1>
                                    <p> Ever wanted to know where you fall on the political spectrum?</p>
                                    <p> Answer a series of questions and we'll try our best to determine where you fall!</p>
                                    <p> It should take less than 5 minutes!</p>
                                    <Link to="/login"><Button variant="contained">Take the Test!</Button></Link>
                                </div>
                            </div>
                        </ScrollAnimation>
                    </section>
                    <section className="landing-contact">
                        <div id="contact" className="landing__spacer"></div>
                        <ScrollAnimation animateOnce duration={0.5} animateIn="animate__fadeInUp">
                            <h1 className="landing-contact__heading">CONTACT US</h1>
                            <div className="landing-contact__container">
                                <div className="landing-contact__info">
                                    <p>Feel free to reach out to us about anything! If you have a suggestion for a new feature or improvements feel free to reach out here!</p>
                                    <b>Email: </b>
                                    <p><a href="mailto:ynwbteam@gmail.com"><i className="far fa-envelope"></i> ynwbteam@gmail.com</a></p>
                                    {/* <a href="https://www.linkedin.com/company/speereducation/"><i className="fab fa-2x fa-linkedin-in"></i></a> */}
                                </div>
                                <ContactUs mainClassName="landing-contact"/>
                            </div>
                        </ScrollAnimation>
                    </section>
                </>}
        </div>
    )
}

export default LandingPage;
