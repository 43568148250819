import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import { useAuth } from '../../contexts/AuthContext';
import Loader from '../../components/Loader/Loader';
import Button from '@material-ui/core/Button/Button';
import GoogleButton from 'react-google-button';
import LoginNavbar from '../../components/Navbar/LoginNavbar';
import './LoginPage.css';

export default function LoginPage() {

    const [loading, setLoading] = useState(false)
    const { user, initGoogleSignIn } = useAuth()


    useEffect(() => {
        if (localStorage.getItem('loading') === "true") {
            setLoading(true)
        }

        return () => {
            localStorage.removeItem('loading')
        }
    }, [])

    const handleLogin = async () => {
        setLoading(true)
        localStorage.setItem('loading', true);
        await initGoogleSignIn()
    }

    const handleReset = () => {
        setLoading(false)
        localStorage.removeItem('loading')
    }

    return (
        <div className="login">
            <LoginNavbar />
            {/* On sign in, redirects the user*/}
            {user ? <Redirect to="/app" /> : null}
            {!loading ?
                <div className="login__container">
                    <h1>Sign Up / Login!</h1>
                    <p>There's <span>no need</span> to create an account, just login with your Google account!</p>
                    <GoogleButton type="light" onClick={handleLogin} />
                </div>
                : <div className="login__loading">
                    <div className="login__loadingLoader">
                        <Loader />
                    </div>
                    {/* <Button variant="contained">Reset</Button> */}
                    <Button variant="contained" onClick={handleReset}>Reset</Button>
                    <p>Resets Login Process <b>(Only click if load takes more than 5 seconds)</b></p>
                </div>}
        </div>
    )
}
