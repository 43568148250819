import React from "react";
import './App.css';
import LandingPage from "./pages/LandingPage/LandingPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import DashboardPage from "./pages/DashboardPage/DashboardPage";
import QuizPage from './pages/QuizPage/QuizPage';
import ScorePage from "./pages/ScorePage/ScorePage";
import ContactUsPage from "./pages/ContactUsPage/ContactUsPage";
import AppNavbar from "./components/Navbar/AppNavbar";
import { useAuth } from "./contexts/AuthContext";
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

function App() {

  const { user } = useAuth();

  return (
    <Router>
      <div className="app">
        <Switch>
          <Route exact path="/">
            <LandingPage />
          </Route>
          <Route exact path="/login">
            <LoginPage />
          </Route>
          {user !== false ? <>
            <Route path="/app">
              <AppNavbar /> 
            </Route>
            <Route exact path="/app">
              <DashboardPage />
            </Route>
            <Route exact path="/app/score">
              <ScorePage />
            </Route>
            <Route exact path="/app/quiz">
              <QuizPage />
            </Route>
            <Route exact path="/app/contact">
              <ContactUsPage/>
            </Route>
            </> : null}
            <Route path="/">
              <Redirect to="/"/>
            </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;